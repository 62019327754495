<template>
  <div>
    <b-card>
      <h2>
        Jaką usługę chcesz przecenić?
      </h2>
      <b-form-select
        v-model="Offer.id"
        :options="alloffers"
        placeholder="Wybierz usługę"
        required
        style="margin-bottom: 10px;"
        @change="getOffer"
      />
    </b-card>

    <div v-if="Offer.id">
      <b-card>
        <h2>
          Promocja
        </h2>

        <div class="w-100">
          <input
            type="date"
            v-model="promotion.date"
            block
            class="w-100"
          />
        </div>

        <label for="time from">
          Czas od:
        </label>
        <b-form-timepicker
          v-model="promotion.time_from"
        />

        <label for="time to">
          Czas do:
        </label>
        <b-form-timepicker
          v-model="promotion.time_to"
        />

        <label for="">
          Cena:
        </label>
        <b-form-input
          v-model="Offer.price"
          placeholder="Cena"
          required
          type="number"
          style="margin-top: 10px;"
          disabled
        />
        <label for="">
          Nowa cena
        </label>
        <b-form-input
          v-model="promotion.new_price"
          placeholder="Cena"
          required
          type="number"
          style="margin-top: 10px;"
        />

      </b-card>
      <b-card>
        <b-button
          type="submit"
          variant="success"
          style=" width: 100%;"
          @click="makeDiscount ()"
        >
          Zmień cenę w danym czasie
        </b-button>
      </b-card>
    </div>
    <div v-if="!Offer.id">
      <b-card>
        <div style="text-align: center;">
          <h2>
            Wybierz ofertę którą chcesz przecenić
          </h2>
        </div>
      </b-card>
    </div>

  </div>
</template>

<script>
import axios from 'axios'
import swal from 'sweetalert2'
import {
  BCard, BCardText, BForm, Bswich, BFormSelect, BFormInput, BButton, BFormTimepicker, BCalendar,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
    BForm,
    Bswich,
    BFormSelect,
    BFormInput,
    BButton,
    BFormTimepicker,
    BCalendar,
  },

  data() {
    return {
      Offer: {},
      alloffers: [],
      promotion: {},
    }
  },
  mounted() {
    document.title = 'Dodaj promocję happy hour - Pickmode'
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    }
    axios.get(`${process.env.VUE_APP_API_URL}my-offers`, config)
      .then(response => {
        this.alloffers = response.data.success
        for (let i = 0; i < this.alloffers.length; i++) {
          this.alloffers[i].text = this.alloffers[i].title
          this.alloffers[i].value = this.alloffers[i].id
        }
      })
    // catch error
      .catch(error => {
        swal.fire({
          title: 'Error',
        })
      })
  },
  methods: {
    getOffer() {
      swal.fire({
        title: 'Wybrano usługę',
        text: this.Offer.title,
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      })
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
      axios.get(`${process.env.VUE_APP_API_URL}Offer/${this.Offer.id}`, config)
        .then(response => {
          this.Offer = response.data.offer
        })
      // catch error
        .catch(error => {
          swal.fire({
            title: 'Error',
          })
        })
    },
    makeDiscount() {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
      axios.post(`${process.env.VUE_APP_API_URL}HappyHours/add`, {
        new_price: this.promotion.new_price,
        time_from: this.promotion.time_from,
        time_to: this.promotion.time_to,
        offer_id: this.Offer.id,
        date: this.promotion.date,
      }, config)
        .then(response => {
          swal.fire({
            title: 'Zmieniono cenę',
            text: 'Cena została zmieniona',
            showConfirmButton: false,
            timer: 3000,
            icon: 'success',
            timerProgressBar: true,
          })
          this.$router.push('/promocje')
        })
      // catch error
        .catch(error => {
          swal.fire({
            title: 'Error',
          })
        })
    },
  },
}
</script>

<style>

</style>
